import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <div className="Section">
        <div>email</div>
        <span>{'$\\text{tellerm<at>proton.me}$'}</span>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="contact" description="HMU"/>

export default Contact
